import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setAccessToken } from '../Services/AuthService';
import Loading from '../Blueprint/Components/Loading';
import Unauthorized from '../Blueprint/Components/Unauthorized';
import axios from '../Config/axios';

const EmbeddedContext = createContext();

export const useEmbedded = () => useContext(EmbeddedContext);

export const EmbeddedProvider = ({ children }) => {
    
    const [isEmbedded, setIsEmbedded] = useState(false);
    const [embedId, setEmbedId] = useState("");
    const [embeddedId, setEmbeddedId] = useState("");

    const location = useLocation();
    
    const token = new URLSearchParams(location.search).get('token');
    const embed_id = new URLSearchParams(location.search).get('embed_id');
    const embedded_id = new URLSearchParams(location.search).get('embedded_id');
    const embedded = new URLSearchParams(location.search).get('embedded');

    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (embedded === 'true'){

            localStorage.removeItem('lastPath');

            localStorage.setItem('access_token', token);
            localStorage.setItem('expires_in', 300);
            sessionStorage.setItem('isEmbedded', true);
            sessionStorage.setItem('embedded_id', embedded_id);
            sessionStorage.setItem('embed_id', embed_id);

            const validateToken = async () => {
                try {
                    const isValid = await checkTokenIsAlreadyValid();
                    setIsEmbedded(true);
                    setEmbedId(embed_id)
                    setEmbeddedId(embedded_id)
                    setIsTokenValid(isValid);
                    setIsLoading(false);
                } catch (error) {
                    setIsTokenValid(false);
                    setIsLoading(false);
                }
            };
    
            validateToken();
        } else {
            setIsLoading(false);
        }
        
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (embedded && !isTokenValid) {
        return <Unauthorized message="An authentication error has occurred. If the problem persists, please contact our support." />;
    }

    return (
        <EmbeddedContext.Provider value={{ isEmbedded, setIsEmbedded, embedId, embeddedId }}>
            {children}
        </EmbeddedContext.Provider>
    );
};

const checkTokenIsAlreadyValid = async () => {

    try {
        const response = await axios.post('/api/embedded');
        setAccessToken(response.data);
        return true;
    } catch (error) {
        return false;
    }
}