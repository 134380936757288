import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEmbedded } from './Contexts/EmbeddedContext';

const EmbeddedGuard = () => {
    
    const { isEmbedded, embedId } = useEmbedded();

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {

        if (isEmbedded && embedId !== 'default') {
            navigate(`/workflow/${embedId}`);
        }

    }, [location.search, navigate]);

    return null;
}

export default EmbeddedGuard;
