import { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { EmbeddedProvider } from './Contexts/EmbeddedContext';
import TokenRefresher from './TokenRefresher';
import EmbeddedGuard from './EmbeddedGuard';

const OAuth2Callback = lazy(() => import('./OAuth2Callback'));
const BlueprintEditor = lazy(() => import('./Blueprint/Editor'));
const Home = lazy(() => import('./Blueprint/Home'));
const Workflows = lazy(() => import('./Blueprint/Workflows'));
const Instances = lazy(() => import('./Blueprint/Instances'));
const Events = lazy(() => import('./Blueprint/Events'));
const Integrations = lazy(() => import('./Blueprint/Integrations'));
const APIKeys = lazy(() => import('./Blueprint/APIKeys'));
const Invoices = lazy(() => import('./Blueprint/Invoices'));
const Templates = lazy(() => import('./Blueprint/Templates'));
const TemplatePreview = lazy(() => import('./Blueprint/TemplatePreview'));
const TemplatePreviewIframe = lazy(() => import('./Blueprint/TemplatePreviewIframe'));

const Loading = () => <div className="flow-editor grid h-screen place-items-center text-gray-500">Loading...</div>;

function App() {

    const location = useLocation();

    useEffect(() => {

        console.log('Starting app... ', new Date().toISOString());

        if (location.pathname !== '/' && location.pathname !== '/oauth2/callback' && location.pathname.indexOf('/public/') === -1) {
            localStorage.setItem('lastPath', location.pathname);
        }

    }, [location.pathname]);

    return (
        <div className="App">
            <Suspense fallback={<Loading />}>
                <EmbeddedProvider>
                    <TokenRefresher />
                    <EmbeddedGuard />   
                    <Routes>
                        <Route path="/oauth2/callback" element={<OAuth2Callback />} />
                        <Route path="/workflow/:workflowId" element={<BlueprintEditor />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/automations" element={<Workflows />} />
                        <Route path="/instances" element={<Instances />} />
                        <Route path="/integrations" element={<Integrations />} />
                        <Route path="/settings/api-keys" element={<APIKeys />} />
                        <Route path="/workflows/instance/:workflowExecutionId" element={<Events />} />
                        <Route path="/invoices" element={<Invoices />} />
                        <Route path="/templates" element={<Templates />} />
                        <Route path="/public/:workflowId" element={<TemplatePreview />} />
                        <Route path="/public/:workflowId/iframe" element={<TemplatePreviewIframe />} />
                    </Routes>
                </EmbeddedProvider>
            </Suspense>
        </div>
    );
}

export default App;
